body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100%;
	overflow: hidden;
}
html {
	overflow: hidden;
	height: 100%;
}

body {
	height: 100%;
	overflow: auto;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.login {
	background-image: url("https://images.unsplash.com/photo-1513224502586-d1e602410265?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1331&q=80");
	background-repeat: no-repeat;
	width: 100%;
	background-size: cover;
	background-position: center;
}

.modal {
	position: fixed;
	width: 400px;
	z-index: 1040;
	top: 50%;
	left: 50%;
	background-color: white;
	border-radius: 3px;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
	transform: translate(-50%, -50%);
}

.backdrop {
	position: fixed;
	z-index: 1040;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #000;
	opacity: 0.5;
}

.modal-header {
	display: flex;
	justify-content: space-between;
	background-color: #145c9e;
	color: white;
}

.modal-title {
	font-weight: bold;
}

.modal-desc,
.modal-header,
.modal-footer {
	padding: 12px;
}

.close-button {
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 1;
	color: white;
	border: none;
	opacity: 0.7;
	cursor: pointer;
}
button {
	cursor: pointer;
	font-size: 1rem;
	padding: 10px 12px;
	border-radius: 5px;
	border: none;
}

.modal-footer {
	border-top: 1px solid #e9ecef;
	display: flex;
	justify-content: flex-end;
	gap: 8px;
}

.card {
	width: 350px;
	height: 400px;
}

.table-sortable th {
	cursor: pointer;
}

.rotate {
	outline: 0;
	transition: ease 100ms;
	/* other styles... */
}

#hacky-input-sn {
	display: none;
	/* Hide the input */
}

#hacky-input-sn:checked + label.rotate {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

#hacky-input-client {
	display: none;
	/* Hide the input */
}

#hacky-input-client:checked + label.rotate {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

#hacky-input-brand {
	display: none;
	/* Hide the input */
}

#hacky-input-brand:checked + label.rotate {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

#hacky-input-status {
	display: none;
	/* Hide the input */
}

#hacky-input-status:checked + label.rotate {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

#hacky-input-date {
	display: none;
	/* Hide the input */
}

#hacky-input-date:checked + label.rotate {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

#hacky-input-name {
	display: none;
	/* Hide the input */
}

#hacky-input-name:checked + label.rotate {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

.pagination-page {
	background-color: black;
	color: white;
	/* Add container styling here */
  }

@tailwind base;
@tailwind components;
@tailwind utilities;